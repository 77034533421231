import React from "react"
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import Search from "../components/search"
import NamesList from "../components/namesList"
import Layout from "../components/layout"
import { useRecoilValue } from "recoil"
import favorites from "../components/favorites"
import _ from "lodash"
import Ad from "../components/ad"



const FavoritesList = () => {
    const names = useRecoilValue(favorites)
    return (
        names.length > 0 ?
            <NamesList
                names={names}
                initial_limit={100}
                title="Favoriten"
            />
            :
            <div className="paper">
                <h2>Favoriten</h2>
                <p>Bisher noch keine Namen ausgewählt</p>
            </div>

    )
}

export default function Favoriten({ data }) {
    const searchExamples = _.shuffle(data.searchExamples.nodes
        .filter(page => page.attributes.length <= 2))
        .slice(0, 7)
    return (
        <Layout>
            <Helmet>
                <title>Vornamensuche mit Herkunft, Bedeutung und Beliebtheit</title>
                <meta name="description"
                    content="Über 16000 männliche und weibliche Vornamen mit Herkunft, 
                Bedeutung und Beliebtheit. "/>
                <meta name="keywords"
                    content="vornamensuche, vornamen, namen, jungennamen, mädchennamen" />
                <link rel="canonical" href='/' />
            </Helmet>
            <Ad id="adnz_maxiboard_1" />
            <FavoritesList />
            <Ad id="adnz_maxiboard_2" />
            <Search searchExamples={searchExamples} />

        </Layout>
    )
}

export const query = graphql`
    query {
        searchExamples : allPagesJson (sort: {fields: number_of_names, order: DESC}, limit: 100) {
        nodes {
            attributes {
            imagePath
            id
            }
            number_of_names
            title
            url
        }
        }
    }`
